import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Area = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Abm/Area.vue");
const Division = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Abm/Division.vue");
const Categoria = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Abm/Categoria.vue");
const Brand = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Abm/Brand.vue");

const Features = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Abm/Features.vue");

const Pendientes = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Taxonomia/Pendiente.vue");

const Taxonomizado = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Taxonomia/Taxonomizado.vue");

const Descartados = () =>
    import ( /* webpackChunkName: "dashboard" */ "@/pages/Taxonomia/Descartados.vue");


const Login = () =>
    import ( /* webpackChunkName: "common" */ "@/pages/Login.vue");

const ComparativoDePrecios = () =>
    import (/* webpackChunkName: "common" */ "@/pages/ComparativoDePrecios.vue");

const HistoricoDePrecios = () =>
    import (/* webpackChunkName: "common" */ "@/pages/HistoricoDePrecios.vue");

const Auth = (to) => {
    if (!to) {
        return window.localStorage.getItem('http_token') && window.localStorage.getItem('http_token') != 'null' ? 'abm/area' : 'login';
    } else {
        return window.localStorage.getItem('http_token') && window.localStorage.getItem('http_token') != 'null' ? true : false;
    }
}

const routes = [{
    path: "/login",
    name: "login",
    component: Login
}, {

    path: "/",
    component: DashboardLayout,
    redirect: to => {
        return Auth(false);
    },
    children: [{
        path: "/abm/area",
        name: "Area",
        component: Area,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/abm/division",
        name: "Division",
        component: Division,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/abm/categoria",
        name: "Categoria",
        component: Categoria,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/abm/features",
        name: "Features",
        component: Features,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/abm/brand",
        name: "Brand",
        component: Brand,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        //Routes TAXONOMIA
        path: "/taxonomia/pendiente",
        name: "Pendiente",
        component: Pendientes,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/taxonomia/taxonomizado",
        name: "Taxonomizado",
        component: Taxonomizado,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/taxonomia/descartados",
        name: "Descartados",
        component: Descartados,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/historico/prices",
        name: "History prices",
        component: HistoricoDePrecios,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, {
        path: "/comparativo/precios",
        name: "Price comparison",
        component: ComparativoDePrecios,
        beforeEnter: (to, from, next) => {
            (Auth(to)) ? next(): next("login");
        }
    }, ]
}, {
    path: "*",
    component: NotFound
}, ];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;