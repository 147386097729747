import axios from "axios";
import env from "../env.js";

export const UsersServices = {
  async single(user_id) {
    return axios.get(env.API+"/users/single/"+user_id);
  },
  async update(payload, id) {
    return axios.put(env.API+"/users/update/"+id,payload);
  }
};
export default { UsersServices };