<template>
  <component
    :is="tag"
    @click.native="hideSidebar"
    class="nav-item"
    v-bind="$attrs"
    tag="li"
  >
    <a :class="'nav-link ' + type">
      <slot>
        <i v-if="icon" :class="icon" style="font-size: 14px"></i>
        <div class="text-and-badge">
          <div>
            <p>{{ name }}</p>
          </div>
          <div class="badge-content" v-if="value > 0">
            <Badge
              :value="value"
              class="badge-text p-mr-2"
              style="font-size: 9px; border-radius: 50%"
              size="small"
            ></Badge>
          </div>
        </div>
      </slot>
    </a>
  </component>
</template>
<script>
import Badge from "primevue/badge";

import "primeflex/primeflex.css";

export default {
  name: "sidebar-link",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    value: Number,
    type: String,
    tag: {
      type: String,
      default: "router-link",
    },
  },
  components: {
    Badge,
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style scoped>
.nav-item {
  border-left: 1px !important;
  border-left-style: solid !important;
  border-left-color: transparent !important;
  transition: all 0.3s !important;
  outline: none !important;
}

.nav-item.active {
  border-left: 1px !important;
  border-left-style: solid !important;
  border-left-color: rgb(0, 140, 255) !important;
  list-style: none !important;
}

.nav-item.active.router-link-active {
  background-color: #15232e !important;
  border-radius: 0px !important;
  transition: all 0.3s !important;
}

.nav-item:hover {
  background-color: #15232e !important;
  border-radius: 0px !important;
  transition: all 0.3s !important;
  border-left-color: rgb(0, 140, 255) !important;
  color: white !important;
}

.nav-item:hover > .nav-link > .text-and-badge > div > p {
  color: white !important;
}

.nav-link {
  text-decoration: none;
  position: static !important;
  margin: 0px !important;
  padding: 5px !important;
  outline: none;
}

.text-and-badge {
  display: flex;
}

i {
  color: #a7a7a7 !important;
  margin: 0px !important;
}

.text-and-badge > div {
  vertical-align: middle;
  align-self: center;
  align-items: center;
}

.text-and-badge > div > p {
  color: white;
  font-size: 10px;
  font-weight: 800;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.text-and-badge > .badge-content {
  flex: 1;
  justify-content: end;
  text-align: end;
}

.text-and-badge > .badge-content > .badge-text {
  background-color: #ed3b55;
  font-size: 0.6rem;
}

.nav-item > .sub > .text-and-badge > div > p {
  color: rgb(82, 82, 82) !important;
  font-size: 10px;
  font-weight: 800;
}

.active > .sub > .text-and-badge > div > p {
  color: rgb(255, 255, 255) !important;
  font-size: 10px;
  font-weight: 800;
}

.nav-item:hover > .sub > .text-and-badge > div > p {
  color: white !important;
  font-size: 10px;
  font-weight: 800;
}

.sub .nav-link {
  text-decoration: none;
  position: static !important;
  margin: 0px !important;
}

.sub .text-and-badge {
  display: flex;
}

.sub i {
  color: #a7a7a7 !important;
  margin: 0px !important;
}

.sub .text-and-badge > div {
  vertical-align: middle;
  align-self: center;
  align-items: center;
}

.sub .text-and-badge > .badge-content {
  flex: 1;
  justify-content: end;
  text-align: end;
}

.sub .text-and-badge > .badge-content > .badge-text {
  background-color: #ed3b55;
  font-size: 0.6rem;
}

.badge-custom{
  font-size: 9px !important;
  border-radius: 50% !important;
  max-width: 24px !important;
  max-height: 24px !important;
  padding: 0px !important;
}
</style>
