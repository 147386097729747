const session = {
    state: {
        name: 'Session',
        token: null,
        user_id: null,
        user: {},
        config: {
            headers: {
                Authorization: null
            }
        }
    },
    getters: {
        name(state) {
            return state.name
        },
        token(state) {
            return state.token
        },
        user(state) {
            return state.user_id
        },
        getUser(state) {
            return state.user
        }
    },
    mutations: {
        saveToken(state, token) {
            state.token = token;
            state.config.headers.Authorization = token;
            window.localStorage.setItem("http_token", token);
        },
        saveUserId(state, user_id) {
            state.user_id = user_id;
            window.localStorage.setItem("user_id", user_id);
        },
        saveUser(state, user) {
            state.user = user;
        },
    }
}
export default session;