<template>
  <div class="wrapper">
    <side-bar class="menu-lateral">
      <template slot="links">
        <Panel
          :header="abm.header"
          :toggleable="abm.toggleable"
          :collapsed="abm.collapsed"
        >
          <div v-for="(item, idx) in abm.hijos" :key="idx">
            <sidebar-link
              :to="item.to"
              :type="item.type"
              :name="item.name"
            />
          </div>
        </Panel>
        <Panel
          :header="taxonomia.header"
          :toggleable="taxonomia.toggleable"
          :collapsed="taxonomia.collapsed"
        >
          <div v-for="(item, idx) in taxonomia.hijos" :key="idx">
            <sidebar-link
              :to="item.to"
              :type="item.type"
              :name="item.name"
            />
          </div>
        </Panel>
        <sidebar-link
          to="/comparativo/precios"
          name="Comparativa de Precios"
        />
        <sidebar-link
          to="/historico/prices"
          name="Histórico de Precios"
        />

        <SessionDestroy name="Cerrar Sesión"></SessionDestroy>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content> </dashboard-content>

      <content-footer></content-footer>
    </div>
    {{ routePath }}
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import SessionDestroy from "../../components/SessionDestroy.vue";
import Panel from "../../components/panelAdapter/panel";
import { UsersServices } from "../../services/UsersServices";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SessionDestroy,
    Panel,
  },

  data() {
    return {
      abm: {
        header: "Abm",
        icon: "pi pi-user",
        toggleable: true,
        collapsed: true, //FLASE = OPEN || TRUE = CLOSED
        hijos: [
          {
            to: "/abm/area",
            type: "sub",
            name: "Área",
          },
          {
            to: "/abm/division",
            type: "sub",
            name: "Division",
          },
          {
            to: "/abm/categoria",
            type: "sub",
            name: "Categoría",
          },
          {
            to: "/abm/features",
            type: "sub",
            name: "Features",
          },
          {
            to: "/abm/brand",
            type: "sub",
            name: "Brand",
          },
        ],
      },
      taxonomia: {
        header: "Taxonomia",
        icon: "pi pi-user",
        toggleable: true,
        collapsed: true,
        hijos: [
          {
            to: "/taxonomia/pendiente",
            type: "sub",
            name: "Pendiente",
          },
          {
            to: "/taxonomia/taxonomizado",
            type: "sub",
            name: "Taxonomizado",
          },
          {
            to: "/taxonomia/descartados",
            type: "sub",
            name: "Descartados",
          },
        ],
      },
    };
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  created() {
    this.Session();
    try {
      var path = this.$route.path;
      var routePai = path.split("/");
      if (routePai.length == 2) {
        this.taxonomia.collapsed = true;
        this.abm.collapsed = true;
      } else {
        routePai.map((item) => {
          try {
            console.log(item);
            if (item == "taxonomia") {
              this.taxonomia.collapsed = false;
            }
            if (item == "abm") {
              this.abm.collapsed = false;
            }
          } catch {}
        });
      }
    } catch (error) {}

  },

  computed: {
    routePath() {
      try {
        var path = this.$route.path;
        var routePai = path.split("/");
        if (routePai.length == 2) {
          this.taxonomia.collapsed = true;
        } else {
          routePai.map((item) => {
            try {
              if (item == "taxonomia") {
                this.taxonomia.collapsed = false;
              } else if (item == "abm") {
                this.abm.collapsed = false;
              }
            } catch {}
          });
        }
      } catch (error) {}
    },
  },

  methods: {
    Session() {
      if (
        !window.localStorage.getItem("http_token") &&
        window.localStorage.getItem("http_token") != "null" &&
        this.$route.name != "login"
      ) {
        this.$store.commit("saveToken", window.localStorage.getItem("http_token"));
        this.$store.commit(
          "saveUserId",
          window.localStorage.getItem("user_id")
        );
        window.location.href = window.location.origin + "/#/login";
      } else {
        this.$store.commit("saveToken", window.localStorage.getItem("http_token"));
        this.$store.commit(
          "saveUserId",
          window.localStorage.getItem("user_id")
        )
        
        this.$http.defaults.headers.common["httptoken"] = this.$store.state.Session.token;
      }

      this.$http.interceptors.response.use(
        (response) => {
          return response;
        },

        (error) => {
          if (error.response.status === 401) {
            this.$store.commit(
              "saveToken",

              window.localStorage.getItem("http_token")
            );

            this.$store.commit(
              "saveUserId",

              window.localStorage.getItem("user_id")
            );

            window.location.href = window.location.origin + "/#/login";
          }

          return error;
        }
      );
    },
  },
};
</script>
<style>
.main-panel {
  border-top: 0px !important;
}
</style>