<template>
  <nav class="fixed-top">
    <div class="container-fluid bg-white border-bottom">
      <div class="nav-bar" style="margin-left: 15px">
        <div class="user">
          <div class="content-user">
            <Avatar label="J" shape="circle" class="p-mr-2" size="40x40" style="background-color: #2196f3; color: #ffffff;"/>
            <h4>Jeferson Ramos</h4>
            <div class="caret-div"><i class="caret d-none d-lg-block d-xl-block"></i></div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import InputText from "primevue/inputtext";
import Avatar from "primevue/avatar";

export default {
  components: {
    CollapseTransition,
    Modal,
    InputText,
    Avatar,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
      methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        console.log(this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      LogOut(){
        this.$store.commit("saveToken",null);
        this.$store.commit("saveUserId",null);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user_id");
        window.location.href = '/#/login';
      }
    }
};
</script>
<style scoped>
  @import "./Css/TopNavBar.css";
</style>
