import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Session from "./modules/session"
import Alerts from "./modules/alerts"

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Session: Session,
        Alerts: Alerts
    }
})