<template>
  <div class="content">
    <Breadcrumb :home="home" :model="items" class="breadcrumb-custom" />
    {{label}}
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->

      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
import Breadcrumb from "primevue/breadcrumb";
import { FadeTransition } from "vue2-transitions";

export default {
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
      items: null,
    };
  },

  created() {

  },

  computed: {
    label(){
      try {
        var path = this.$route.path;
        var paths = path.split('/');

        if(paths.length == 2){

          var item = this.$router.history.current.name;
          console.log(item)
          this.items = [{label: item}]
        }else{
          var obj = [];
          var pathsVerdad = paths.map((item) =>{
            if(!item == ""){
              obj.push({label: item})
            }
          })
          this.items = obj
        }
      } catch (error) {
        
      }
    }
  },

  components: {
    FadeTransition,
    Breadcrumb,
  },
};
</script>
<style>
.p-breadcrumb{
  padding: 0px !important;
  border: none !important;
  background: transparent !important;
}

.content{
  padding-left: 220px !important;
  padding-top: 10px !important
}
</style>
